.question-viewer-data-container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    background-color: #0008;
    z-index: 10000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.module-panel-viewer-data{
    width: 50vw;
    height: 75vh;
    margin: 0 auto;
    position: relative;
    z-index: -1;
}

.viewer-data-content{
    width: 20vw;
    height: 20vw;
    position: absolute;
    top: 26vh;
}

.legend{
    width: 50vw;
    position: absolute;
    top:-140px;
    left: -10vw;
}

.legendChart{
    font-size: 1.2vw;
    display: flex;
    justify-content: flex-start;
    column-gap: 2rem;
    row-gap: 1rem;
    align-items: center;
}

.legendChart-bg{
    width: 50px;
    height: 10px;
}

.question-viewer-data-container > .question-game{
    position: relative;
    width: 50vw;
    top: -4vh;
}

.question-viewer-data-container > .question-game > p{
    font-size: 1.2rem;
    width: 50%;
}

.question-viewer-data-container > .question-game > .module-question{
    width: 100%;
    height: 100%;
}

.display-question-viewer-box-close{
    font-size: 1.2rem;
    font-weight: bolder;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../resources/modulo_competidor_abajo.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: auto;
    width: 250px;
    transition: .3s ease-in-out;
}

.display-question-viewer-box-close:hover{
    scale:1.1;
    cursor: pointer;
}