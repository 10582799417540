.module-option{
    width: 38vw;
    height: 65px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.module-option.a{
    justify-self: self-start;
}
.module-option.b{
    justify-self: self-end;
}
.module-option.c{
    justify-self: self-start;
}
.module-option.d{
    justify-self: self-end;
}

.module-option > img{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.module-option > p{
    margin-left: 7.8rem;
    font-size: 1.2rem;
}