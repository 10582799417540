.panel-options-customer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
}

.panel-options-customer-question{
    font-size: 1.5rem;
    color:#fff;
    font-weight: bold;
    text-shadow: 2px 2px #000;
}

.panel-options-container-customer{
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 80%;
    width: 100%;
}

.option-customer{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    color: #fff;
    background-image: url("../../resources/menu_opt_background.png");
    width: 100%;
    height: 70px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}