.panel-game{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    height: 50vh;
}

.module-panel {
    width: 900px;
    height: 400px;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - 300px);
    left: calc(50% - 450px);
    z-index: -1;
}

p{
    font-size: 1.7rem;
    font-weight: bold;
    padding: 0 10px;
    margin:0;
    
}