.panel-game-qr.visible{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #0008;
    z-index: 10000;
}

.qr-code-box{
    width: 80%;
    height: 80%;
}

.panel-game-qr{
    display: none;
}

.module-panel-qr {
    width: 800px;
    height: 500px;
    margin: 0 auto;
    position: absolute;
    top: calc(50% - 250px);
    left: calc(50% - 400px);
    z-index: -1;
}

p{
    font-size: 1.7rem;
    font-weight: bold;
    padding: 0 10px;
    margin:0;
    
}

.panel-game-qr > .question-game{
    position: relative;
    width: 800px;
    top: auto; 
    bottom: -15vh;
}

.panel-game-qr > .question-game > .module-question{
    width: 100%;
    height: 100%;
}

.display-qr-box-close{
    font-size: 1.2rem;
    font-weight: bolder;
    margin-right: 4vw;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-image: url("../../resources/menu_opt_background.png");
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 100px;
    width: 450px;
    transition: .3s ease-in-out;
}

.display-qr-box-close:hover{
    scale: 1.1;
    cursor: pointer;
}