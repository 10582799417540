.time-box{
    position: absolute;
    width: 20vw;
    height: 70px;
    right: 1vw;
    top: 16vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 100000;
}

.time-module{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.time-box > p{
    margin-right: 3rem;
    font-size: 2.5rem;
}

p.reset-buttom-time{
    position: absolute;
    font-size: 2.1rem;
    top: 8vh;
    right: 0;
    cursor: pointer;
}