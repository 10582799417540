p {
    color:#FFF;
}
.container-game{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: relative;
}

.logo-img-game{
    width: 18vw;
    height: auto;
    position: absolute;
    top:-.7rem;
    z-index: -1;
}

.background-img-game{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}